import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../components/loading'
import { Error } from '../components/error'
import { fetch_get } from "../utils/fetch"

const Callback = () => {
    const { isLoading, error, isAuthenticated, user, logout } = useAuth0()
    const [ ssoUrlLoading, setSsoUrlLoading] = useState(true)
    const [ ssoUrl, setSsoUrl] = useState("")
    useEffect(()=>{
      if (isAuthenticated && user.preferred_username) { // SSO login
        fetch_get(`/sso/url`)
          .then((res) => {
            if (res.url) {
              setSsoUrl(res.url)
            } else {
              setSsoUrl('')
              logout({ returnTo: `${process.env.AUTH0_LOGOUT}` })
            }
            setSsoUrlLoading(false)
          })
      } else {
        setSsoUrlLoading(false)
      }
    },[ isAuthenticated, user ])
  
    if (isLoading || ssoUrlLoading) {
      return <Loading />
    }

    return (
        <>
        {error && <Error message={error.message} />}
        {!error && <div>You are not yet completely set up for CodeLinaro. Please go to <a href={ssoUrl}>{ssoUrl}</a></div>}
        </>
    )
}

export default Callback
