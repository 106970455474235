import * as Sentry from "@sentry/gatsby"
import jwt_decode from "jwt-decode"
import { navigate } from "gatsby"
import moment from "moment"

const apiOrigin = process.env.CL_API_ENDPOINT
// ? process.env.CL_API_ENDPOINT
// : "https://api.codelinaro.org/v1"

// When fetch returns a promise, the headers will indicate what the
// status code is but we need to get the body in order to unpack the
// error message returned from the API. Note that there is an explicit
// assumption as to the format of the returned error message.
//
// The reason for these lines:
// .then(res => [res, res.text()])
// .then(data => handleErrors(url, data[0], data[1]))
// is to allow us to retain the original response object whilst
// then awaiting the body. handleErrors then tries to decode the
// body as JSON and handles that if an error occurs.

function handleErrors(url, response, resultPromise) {
  return resultPromise.then(result => {
    // Try to convert the result to JSON
    try {
      var resultJSON = JSON.parse(result)
      if (response.ok) return resultJSON
    } catch (err) {
      Sentry.withScope(function (scope) {
        scope.setTag("url", url)
        Sentry.captureMessage(result)
        Sentry.captureException(err)
      })
      throw Error(result)
    }

    // If we get to here, we got a not-OK response back from the server
    // and it decoded into JSON OK.
    if ("status" in resultJSON && "description" in resultJSON["status"]) {
      if (typeof resultJSON["status"]["description"] === "string") {
        if (resultJSON?.status?.code === 403) {
          throw Error("Permission Denied")
        } else {
          throw Error(resultJSON["status"]["description"])
        }
      } else {
        // POST /v1/projects/{project-slug}/git/repo 409 error
        throw resultJSON["status"]["description"]
      }
    }
    throw Error(result)
  })
}

function build_headers(accessToken) {
  var headers = {
    "Content-Type": "application/json",
  }
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`
  }
  return headers
}

function check_password_expired(accessToken) {
  // console.log(moment.utc().format());

  if (accessToken) {
    const user = jwt_decode(accessToken)
    // console.log("cp", user.sub !== undefined && user.sub !== null && !user.sub.startsWith("auth0"))
    if (user.sub !== undefined && user.sub !== null && !user.sub.startsWith("auth0")) return false
    const resetAt = user["https://api.codelinaro.org/reset_at"]
    if (moment().isAfter(moment(resetAt).add({ months: 6 }))) {
      if (
        window.location.pathname === "/secure/settings/" ||
        window.location.pathname === "/secure/settings"
      ) {
        return true
      } else {
        navigate("/secure/settings/")
        return true
      }
    }
  }
  return false
}

export function fetch_put(url, data, accessToken, apiEndPoint = apiOrigin) {
  if (check_password_expired(accessToken)) {
    return new Promise((resolve, reject) => {
      reject("Password Expired, please update your password.")
    })
  } else {
    return fetch(`${apiEndPoint}/${url}`, {
      method: "PUT",
      headers: build_headers(accessToken),
      body: JSON.stringify(data),
    })
      .then(res => [res, res.text()])
      .then(data => handleErrors(url, data[0], data[1]))
  }
}

export function fetch_patch(url, data, accessToken, apiEndPoint = apiOrigin) {
  const password_expired = check_password_expired(accessToken)
  if (password_expired) {
    return new Promise((resolve, reject) => {
      reject("Password Expired, please update your password.")
    })
  } else {
    return fetch(`${apiEndPoint}/${url}`, {
      method: "PATCH",
      headers: build_headers(accessToken),
      body: JSON.stringify(data),
    })
      .then(res => [res, res.text()])
      .then(data => handleErrors(url, data[0], data[1]))
  }
}

export function fetch_post(url, data, accessToken, apiEndPoint = apiOrigin) {
  if (!url.endsWith("/change_password") && check_password_expired(accessToken)) {
    return new Promise((resolve, reject) => {
      reject("Password Expired, please update your password.")
    }).then(data => handleErrors(url, data[0], data[1]))
    // throw Error({ status: { description: "Password Expired" } })
  } else {
    return fetch(`${apiEndPoint}/${url}`, {
      method: "POST",
      headers: build_headers(accessToken),
      body: JSON.stringify(data),
    })
      .then(res => [res, res.text()])
      .then(data => handleErrors(url, data[0], data[1]))
  }
}

export function fetch_get(url, accessToken, apiEndPoint = apiOrigin) {
  if (check_password_expired(accessToken)) {
    return new Promise((resolve, reject) => {
      reject("Password Expired, please update your password.")
    })
  } else {
    return fetch(`${apiEndPoint}/${url}`, {
      headers: build_headers(accessToken),
    })
      .then(res => [res, res.text()])
      .then(data => handleErrors(url, data[0], data[1]))
  }
}

export function fetch_delete(url, data, accessToken, apiEndPoint = apiOrigin) {
  if (check_password_expired(accessToken)) {
    return new Promise((resolve, reject) => {
      reject("Password Expired, please update your password.")
    })
  } else {
    return fetch(`${apiEndPoint}/${url}`, {
      method: "DELETE",
      headers: build_headers(accessToken),
      body: JSON.stringify(data),
    })
      .then(res => [res, res.text()])
      .then(data => handleErrors(url, data[0], data[1]))
  }
}
